export enum RoutesNames {
  AllocationsPending = 'allocations.pending',
  AllocationsApproved = 'allocations.approved',
  AllocationsScheduled = 'allocations.upcoming',
  AllocationsUpload = 'allocations.upload',

  DownloadsCenter = 'downloadsCenter',

  InventoryInventories = 'inventory.inventories',
  InventorySkus = 'inventory.skus',
  InventoryLocations = 'inventory.locations',
  InventoryTransactions = 'inventory.transactions',

  SpecialEventsPending = 'specialEvents.pending',
  SpecialEventsApproved = 'specialEvents.approved',
  SpecialEventsUpcoming = 'specialEvents.upcoming',
  SpecialEventsUpload = 'specialEvents.upload',
  SpecialEventsAnalysis = 'specialEvents.analysis',

  StoreTransferRecommendationsTrips = 'storeTransfer.recommendations.trips',
  StoreTransferRecommendationsProducts = 'storeTransfer.recommendations.products',
}
