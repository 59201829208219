import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { uniqBy } from 'lodash-es';
import type { RouteLocation } from 'vue-router';
import { config } from '../../../app/config';
import { useUiStatesApi, useUiStateAutoPersist } from '../../ui-states';

const STORE_KEY = 'recently-visited/store';
const UI_STATES_KEY = STORE_KEY;

interface Record {
  name: RouteLocation['name'];
  path: RouteLocation['path'];
  visitedAt: number;
}

export const useRecentlyVisitedStore = defineStore(
  STORE_KEY,
  () => {
    const records = ref<Record[]>([]);

    function add(route: RouteLocation) {
      const { name, path } = route;

      records.value = uniqBy<Record>(
        [{ name, path, visitedAt: Date.now() }, ...records.value],
        'path',
      ).slice(0, 5);
    }

    const api = useUiStatesApi();

    const fetching = ref(false);
    const fetched = ref(false);

    let fetchingPromise: Promise<void> | null = null;

    async function fetch(force = false) {
      if (fetched.value && !force) {
        return fetchingPromise ?? Promise.resolve();
      }

      if (!fetchingPromise) {
        fetchingPromise = (async () => {
          fetching.value = true;

          const persistedState = await api
            .getUiStates({ key: UI_STATES_KEY })
            .then(({ data }) => data.data[0]);

          if (persistedState) {
            // if records were added before initial store fetch - merge them
            records.value = [
              ...(!fetched.value ? records.value : []),
              ...persistedState.value.records,
            ].slice(0, 5);
          }

          fetching.value = false;
          fetched.value = true;
          fetchingPromise = null;
        })();
      }

      return fetchingPromise;
    }

    const persistingValue = computed(() => ({
      records: [...records.value],
    }));

    useUiStateAutoPersist({
      enabled: fetched,
      value: persistingValue,
      key: UI_STATES_KEY,
    });

    // Sync between tabs
    window.addEventListener('storage', (event) => {
      if (event.key === `${config.piniaStoreKeyPrefix}${STORE_KEY}`) {
        const store = useRecentlyVisitedStore();
        store.$hydrate();
      }
    });

    return {
      fetching,
      fetched,
      fetch,
      records,
      add,
    };
  },
  {
    persist: {
      pick: ['records'],
    },
  },
);
