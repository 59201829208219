import { defineStore } from 'pinia';
import { computed, onUnmounted, ref, watch } from 'vue';
import { useTimeoutPoll } from '@vueuse/core';
import { useRequestError } from '../../../composables';
import { useUiStateAutoPersist, useUiStatesApi } from '../../ui-states';
import { useDownloadsApi } from '../services';
import type { Download } from '../interfaces';
import { StoreNames } from '../../../shared/store-names';

const UI_STATES_KEY = StoreNames.DownloadsWidget;

export const useDownloadsWidgetStore = defineStore(StoreNames.DownloadsWidget, () => {
  const { getDownloadsByIds } = useDownloadsApi();
  const { getUiStates } = useUiStatesApi();
  const { parseError, requestError, clearError } = useRequestError(); // TODO: do we need to use request error here?

  const downloads = ref<Download[]>([]);
  const allFailed = computed(() => downloads.value.every(({ status }) => status === 'failed'));
  const allCompleted = computed(() =>
    downloads.value.every(({ status }) => status === 'completed'),
  );
  const pendingDownloads = computed(() =>
    downloads.value.filter(({ status }) =>
      ['initializing', 'processing', 'cancelling'].includes(status),
    ),
  );
  const hasPendingItems = computed(() => pendingDownloads.value.length > 0);

  const panelOpen = ref(true);

  // Fetching

  const fetching = ref(false);
  const fetched = ref(false);

  let fetchingPromise: Promise<void> | null = null;

  async function fetch(force = false) {
    if (fetched.value && !force) {
      return fetchingPromise ?? Promise.resolve();
    }

    if (!fetchingPromise) {
      fetchingPromise = (async () => {
        fetching.value = true;

        const persistedState = await getUiStates({ key: UI_STATES_KEY }).then(
          ({ data }) => data.data[0],
        );

        if (persistedState) {
          downloads.value = persistedState.value.downloads || [];
          panelOpen.value = persistedState.value.panelOpen ?? false;
        }

        fetching.value = false;
        fetched.value = true;
        fetchingPromise = null;
      })();
    }

    return fetchingPromise;
  }

  const persistingState = computed(() => ({
    downloads: [...downloads.value],
    panelOpen: panelOpen.value,
  }));

  useUiStateAutoPersist({
    enabled: fetched,
    value: persistingState,
    key: UI_STATES_KEY,
  });

  // Updating

  const updatingDownloads = ref(false);
  const autoUpdateInterval = ref(1000 * 5);

  let updatingDownloadsPromise: Promise<void> | null = null;
  let resumeTimeout: ReturnType<typeof setTimeout>;

  async function updateDownloads() {
    if (!downloads.value.length) {
      return Promise.resolve();
    }

    if (!updatingDownloadsPromise) {
      updatingDownloadsPromise = (async () => {
        updatingDownloads.value = true;

        try {
          clearError();
          const { data } = await getDownloadsByIds(downloads.value.map(({ id }) => id));

          downloads.value = data.data;
        } catch (error) {
          parseError(error);
          console.error(error);
        } finally {
          updatingDownloads.value = false;
          updatingDownloadsPromise = null;
        }
      })();
    }

    return updatingDownloadsPromise;
  }

  const { pause, resume } = useTimeoutPoll(
    async () => await updateDownloads(),
    autoUpdateInterval,
    {
      immediate: false,
    },
  );

  async function addDownloadById(id: string) {
    pause();

    const { data } = await getDownloadsByIds([id]);
    const download = data.data?.[0];

    if (download) {
      if (updatingDownloadsPromise) {
        await updatingDownloadsPromise;
      }
      downloads.value.unshift(download);
    }
    resume();
  }

  function removeDownloadById(id: string) {
    downloads.value = downloads.value.filter((download) => download.id !== id);
  }

  function clear() {
    downloads.value = [];
    panelOpen.value = true;
    clearTimeout(resumeTimeout);
  }

  function togglePanel() {
    panelOpen.value = !panelOpen.value;
  }

  watch(
    hasPendingItems,
    (value) => {
      pause();
      clearTimeout(resumeTimeout);

      if (!value) {
        return;
      }

      resumeTimeout = setTimeout(resume, autoUpdateInterval.value);
    },
    { deep: true },
  );

  onUnmounted(() => {
    clearTimeout(resumeTimeout);
  });

  return {
    hasPendingItems,
    allCompleted,
    allFailed,
    updatingDownloads,
    downloads,
    clear,
    addDownloadById,
    removeDownloadById,
    togglePanel,
    panelOpen,
    fetch,
    fetched,
    fetching,
    requestError,
  };
});
