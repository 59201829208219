import { defineStore } from 'pinia';
import { Schema, z } from 'zod';
import { computed, ref } from 'vue';
import { cloneDeep } from 'lodash-es';
import { StoreNames } from '../../../shared/store-names';
import { useAccountStore } from '../../../stores';
import { useDataTableSettings } from '../../../composables';
import { useUiStatesApi, useUiStateAutoPersist } from '../../ui-states';
import type { SkuFilter } from '../interfaces';
import { SkusColumns as Columns } from '../shared/skus-columns';

const UI_STATES_KEY = StoreNames.InventorySkusPage;

export const useInventorySkusPageStore = defineStore(StoreNames.InventorySkusPage, () => {
  const defaultAppliedFilters: SkuFilter = {
    product_id: [],
    department_id: [],
    seasons: [],
    brands: [],
    categories: [],
    styles: [],
    at_wh: [null, null],
    avoid_replenishment: null,
  };

  const appliedFilters = ref<SkuFilter>(cloneDeep(defaultAppliedFilters));

  const customColumns = ref<string[]>([]);

  // TODO: move filters to useDataTableSettings?

  const {
    columnsVisibility,
    columnsWidth,
    columnsOrder,
    visibleColumns,
    resetColumnsSettings,
    applyPersistedState,
    columnsSettingsTouched,
    pinnedColumns,
    sorting,
    resetSorting,
    handleColumnResize,
    userColumnsWidth,
  } = useDataTableSettings({
    defaultColumnsVisibility: {
      [Columns.Name]: true,
      [Columns.ExternalId]: false,
      [Columns.Description]: false,
      [Columns.ProductName]: true,
      [Columns.ProductExternalId]: false,
      [Columns.Size]: true,
      [Columns.Styles]: true,
      [Columns.Colors]: true,
      [Columns.Categories]: true,
      [Columns.DepartmentName]: true,
      [Columns.DepartmentExternalId]: true,
      [Columns.Brands]: true,
      [Columns.Seasons]: true,
      [Columns.Markets]: true,
      [Columns.Cost]: true,
      [Columns.Price]: true,
      [Columns.SiteQty]: true,
      [Columns.TransitQty]: true,
      [Columns.WhQty]: true,
      [Columns.ReservedQty]: true,
      [Columns.PackConstraint]: true,
      [Columns.TotalStores]: false,
      [Columns.SaleRate]: false,
      [Columns.Coverage]: false,
      [Columns.AvoidReplenishment]: true,
      [Columns.CreatedAt]: true,
    },
    defaultColumnsWidth: {
      [Columns.Name]: 256,
      [Columns.ExternalId]: 256,
      [Columns.Description]: 256,
      [Columns.ProductName]: 256,
      [Columns.ProductExternalId]: 256,
      [Columns.Size]: 256,
      [Columns.Styles]: 168,
      [Columns.Colors]: 168,
      [Columns.Categories]: 168,
      [Columns.DepartmentName]: 256,
      [Columns.DepartmentExternalId]: 256,
      [Columns.Brands]: 168,
      [Columns.Seasons]: 168,
      [Columns.Markets]: 168,
      [Columns.Cost]: 168,
      [Columns.Price]: 168,
      [Columns.SiteQty]: 168,
      [Columns.TransitQty]: 168,
      [Columns.WhQty]: 168,
      [Columns.ReservedQty]: 168,
      [Columns.PackConstraint]: 256,
      [Columns.TotalStores]: 256,
      [Columns.SaleRate]: 168,
      [Columns.Coverage]: 168,
      [Columns.AvoidReplenishment]: 256,
      [Columns.CreatedAt]: 168,
    },
    defaultColumnsOrder: [
      Columns.Name,
      Columns.ExternalId,
      Columns.Description,
      Columns.ProductName,
      Columns.ProductExternalId,
      Columns.Size,
      Columns.Styles,
      Columns.Colors,
      Columns.Categories,
      Columns.DepartmentName,
      Columns.DepartmentExternalId,
      Columns.Brands,
      Columns.Seasons,
      Columns.Markets,
      Columns.Cost,
      Columns.Price,
      Columns.SiteQty,
      Columns.TransitQty,
      Columns.WhQty,
      Columns.ReservedQty,
      Columns.PackConstraint,
      Columns.TotalStores,
      Columns.SaleRate,
      Columns.Coverage,
      Columns.AvoidReplenishment,
      Columns.CreatedAt,
    ],
    defaultPinnedColumns: [Columns.Name, Columns.ExternalId],
    customColumns,
  });

  function reset() {
    resetColumnsSettings();
    resetSorting();
    appliedFilters.value = cloneDeep(defaultAppliedFilters);
  }

  const api = useUiStatesApi();
  const accountStore = useAccountStore();

  const fetching = ref(false);
  const fetched = ref(false);

  let fetchingPromise: Promise<void> | null = null;

  async function fetch(force = false) {
    if (fetched.value && !force) {
      return fetchingPromise ?? Promise.resolve();
    }

    if (!fetchingPromise) {
      fetchingPromise = (async () => {
        fetching.value = true;

        const [persistedState] = await Promise.all([
          api.getUiStates({ key: UI_STATES_KEY }).then(({ data }) => data.data[0]),
          accountStore.fetch(),
        ]);

        customColumns.value =
          accountStore.customDataSchema.Sku?.map(({ key }) => `custom_data.${key}`) ?? [];

        reset();

        // Apply persisted state

        if (persistedState) {
          applyPersistedState(persistedState.value);

          if (persistedState.value.appliedFilters) {
            try {
              appliedFilters.value = cloneDeep(
                z
                  .object(
                    Object.entries(defaultAppliedFilters).reduce<Record<string, Schema>>(
                      (acc, [key, value]) => {
                        // Multi select
                        if (Array.isArray(value)) {
                          acc[key] = z
                            .array(
                              z.string().or(z.object({ value: z.string(), label: z.string() })),
                            )
                            .catch(value as string[]);
                          return acc;
                        }

                        // Flag
                        if (value === null || typeof value === 'boolean') {
                          acc[key] = z.boolean().nullable().catch(value);
                          return acc;
                        }

                        return acc;
                      },
                      {},
                    ),
                  )
                  .parse(persistedState.value.appliedFilters) as SkuFilter,
              );
            } catch (error) {
              // do nothing
            }
          }
        }

        fetching.value = false;
        fetched.value = true;
        fetchingPromise = null;
      })();
    }

    return fetchingPromise;
  }

  const persistingValue = computed(() => ({
    columnsVisibility: { ...columnsVisibility.value },
    columnsWidth: { ...userColumnsWidth.value },
    columnsOrder: [...columnsOrder.value],
    pinnedColumns: [...pinnedColumns.value],
    sorting: { ...sorting.value },
    appliedFilters: { ...appliedFilters.value },
  }));

  useUiStateAutoPersist({
    enabled: fetched,
    value: persistingValue,
    key: UI_STATES_KEY,
  });

  return {
    fetching,
    fetched,
    fetch,
    reset,
    resetColumnsSettings,
    columnsVisibility,
    columnsWidth,
    visibleColumns,
    columnsOrder,
    pinnedColumns,
    sorting,
    appliedFilters,
    columnsSettingsTouched,
    handleColumnResize,
    resetSorting,
  };
});
