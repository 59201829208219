import { defineStore } from 'pinia';
import { ref } from 'vue';
import { StoreNames } from '../../../shared/store-names';
import { useSupersetApi } from '../services';
import { SupersetDashboardData } from '../interfaces';

export const useSupersetDashboardsStore = defineStore(StoreNames.SupersetDashboards, () => {
  const dashboards = ref<SupersetDashboardData[]>([]);

  const api = useSupersetApi();

  const fetching = ref(false);
  const fetched = ref(false);
  let fetchingPromise: Promise<void> | null = null;

  async function fetch(force?: boolean) {
    if (fetched.value && !force) {
      return fetchingPromise ?? Promise.resolve();
    }

    if (!fetchingPromise) {
      fetchingPromise = (async () => {
        fetching.value = true;

        const { data } = await api.getSupersetDashboards();

        dashboards.value = data.data;

        fetching.value = false;
        fetched.value = true;
        fetchingPromise = null;
      })();
    }

    return fetchingPromise;
  }

  return {
    fetching,
    fetched,
    fetch,
    dashboards,
  };
});
