import { createSharedComposable } from '@vueuse/shared';
import { SupersetDashboardData } from '../../interfaces';
import { useApi } from '../../../../services/api';

export const useSupersetApi = createSharedComposable(() => {
  const { transport } = useApi();

  function getSupersetDashboards(moduleTab?: string) {
    return transport.get<{ data: SupersetDashboardData[] }>('v1/supersets/dashboards', {
      query: { module_tab: moduleTab },
    });
  }

  function getSupersetGuestToken(dashboardUuid: string) {
    return transport.post<{ token: string }>(`v1/supersets/guest_token/${dashboardUuid}`, {});
  }

  return {
    getSupersetDashboards,
    getSupersetGuestToken,
  };
});
