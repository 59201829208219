import { defineStore } from 'pinia';
import { z, type Schema } from 'zod';
import { computed, ref } from 'vue';
import { cloneDeep } from 'lodash-es';
import { StoreNames } from '../../../shared/store-names';
import { useAccountStore } from '../../../stores';
import { useDataTableSettings } from '../../../composables';
import { useUiStatesApi, useUiStateAutoPersist } from '../../ui-states';
import type { InventoriesFilter } from '../interfaces';
import { InventoriesColumns as Columns } from '../shared/inventories-columns';

const UI_STATES_KEY = StoreNames.InventoryInventoriesPage;

export const useInventoryInventoriesPageStore = defineStore(
  StoreNames.InventoryInventoriesPage,
  () => {
    const defaultAppliedFilters: InventoriesFilter = {
      location_id: [],
      product_id: [],
      sku_id: [],
      location_type: [],
      styles: [],
      categories: [],
      seasons: [],
      brands: [],
      classifications: [],
      region: [],
      department: [],
      at_wh: [null, null],
      site_qty: [null, null],
      transit_qty: [null, null],
      avoid_replenishment: null,
      location_avoid_replenishment: null,
      sku_avoid_replenishment: null,
    };

    const appliedFilters = ref<InventoriesFilter>(cloneDeep(defaultAppliedFilters));

    const customColumns = ref<string[]>([]);

    // TODO: move filters to useDataTableSettings?

    const {
      columnsVisibility,
      columnsWidth,
      columnsOrder,
      visibleColumns,
      resetColumnsSettings,
      applyPersistedState,
      columnsSettingsTouched,
      pinnedColumns,
      sorting,
      resetSorting,
      handleColumnResize,
      userColumnsWidth,
    } = useDataTableSettings({
      defaultColumnsVisibility: {
        [Columns.LocationName]: true,
        [Columns.LocationExternalId]: false,
        [Columns.SkuName]: true,
        [Columns.SkuExternalId]: false,
        [Columns.SkuDescription]: false,
        [Columns.LocationDescription]: false,
        [Columns.ProductName]: true,
        [Columns.ProductExternalId]: false,
        [Columns.Size]: true,
        [Columns.SourceLocationExternalId]: false,
        [Columns.Styles]: false,
        [Columns.Colors]: false,
        [Columns.Categories]: false,
        [Columns.DepartmentName]: true,
        [Columns.DepartmentExternalId]: false,
        [Columns.Brands]: false,
        [Columns.Seasons]: false,
        [Columns.Markets]: false,
        [Columns.Cost]: false,
        [Columns.Price]: false,
        [Columns.RegionName]: false,
        [Columns.CityName]: false,
        [Columns.LocationType]: false,
        [Columns.Classifications]: false,
        [Columns.SourceLocations]: false,
        [Columns.SiteQty]: true,
        [Columns.TransitQty]: true,
        [Columns.AtWh]: true,
        [Columns.LastStatusDate]: true,
        [Columns.ReservedQty]: true,
        [Columns.SaleRate]: false,
        [Columns.OptimalStock]: false,
        [Columns.MinTarget]: false,
        [Columns.MaxTarget]: false,
        [Columns.Coverage]: false,
        [Columns.CreatedAt]: false,
        [Columns.AvoidReplenishment]: true,
        [Columns.AvoidReplenishmentForLocation]: true,
        [Columns.AvoidReplenishmentForSku]: true,
      },
      defaultColumnsWidth: {
        [Columns.LocationName]: 256,
        [Columns.LocationExternalId]: 256,
        [Columns.SkuName]: 256,
        [Columns.SkuExternalId]: 256,
        [Columns.SkuDescription]: 256,
        [Columns.LocationDescription]: 256,
        [Columns.ProductName]: 256,
        [Columns.ProductExternalId]: 256,
        [Columns.Size]: 168,
        [Columns.SourceLocationExternalId]: 256,
        [Columns.Styles]: 168,
        [Columns.Colors]: 168,
        [Columns.Categories]: 168,
        [Columns.DepartmentName]: 256,
        [Columns.DepartmentExternalId]: 256,
        [Columns.Brands]: 168,
        [Columns.Seasons]: 168,
        [Columns.Markets]: 168,
        [Columns.Cost]: 168,
        [Columns.Price]: 168,
        [Columns.RegionName]: 256,
        [Columns.CityName]: 256,
        [Columns.LocationType]: 168,
        [Columns.Classifications]: 168,
        [Columns.SourceLocations]: 168,
        [Columns.SiteQty]: 168,
        [Columns.TransitQty]: 168,
        [Columns.AtWh]: 168,
        [Columns.LastStatusDate]: 168,
        [Columns.ReservedQty]: 168,
        [Columns.SaleRate]: 168,
        [Columns.OptimalStock]: 168,
        [Columns.MinTarget]: 168,
        [Columns.MaxTarget]: 168,
        [Columns.Coverage]: 168,
        [Columns.CreatedAt]: 168,
        [Columns.AvoidReplenishment]: 168,
        [Columns.AvoidReplenishmentForLocation]: 256,
        [Columns.AvoidReplenishmentForSku]: 256,
      },
      defaultColumnsOrder: [
        Columns.LocationName,
        Columns.LocationExternalId,
        Columns.SkuName,
        Columns.SkuExternalId,
        Columns.SkuDescription,
        Columns.LocationDescription,
        Columns.ProductName,
        Columns.ProductExternalId,
        Columns.Size,
        Columns.SourceLocationExternalId,
        Columns.Styles,
        Columns.Colors,
        Columns.Categories,
        Columns.DepartmentName,
        Columns.DepartmentExternalId,
        Columns.Brands,
        Columns.Seasons,
        Columns.Markets,
        Columns.Cost,
        Columns.Price,
        Columns.RegionName,
        Columns.CityName,
        Columns.LocationType,
        Columns.Classifications,
        Columns.SourceLocations,
        Columns.SiteQty,
        Columns.TransitQty,
        Columns.AtWh,
        Columns.LastStatusDate,
        Columns.ReservedQty,
        Columns.SaleRate,
        Columns.OptimalStock,
        Columns.MinTarget,
        Columns.MaxTarget,
        Columns.Coverage,
        Columns.CreatedAt,
        Columns.AvoidReplenishment,
        Columns.AvoidReplenishmentForLocation,
        Columns.AvoidReplenishmentForSku,
      ],
      defaultPinnedColumns: [
        Columns.LocationName,
        Columns.LocationExternalId,
        Columns.SkuName,
        Columns.SkuExternalId,
      ],
      customColumns,
    });

    function reset() {
      resetColumnsSettings();
      resetSorting();
      appliedFilters.value = cloneDeep(defaultAppliedFilters);
    }

    const api = useUiStatesApi();
    const accountStore = useAccountStore();

    const fetching = ref(false);
    const fetched = ref(false);

    let fetchingPromise: Promise<void> | null = null;

    async function fetch(force = false) {
      if (fetched.value && !force) {
        return fetchingPromise ?? Promise.resolve();
      }

      if (!fetchingPromise) {
        fetchingPromise = (async () => {
          fetching.value = true;

          const [persistedState] = await Promise.all([
            api.getUiStates({ key: UI_STATES_KEY }).then(({ data }) => data.data[0]),
            accountStore.fetch(),
          ]);

          customColumns.value =
            accountStore.customDataSchema.Inventory?.map(({ key }) => `custom_data.${key}`) ?? [];

          reset();

          if (persistedState) {
            applyPersistedState(persistedState.value);

            if (persistedState.value.appliedFilters) {
              try {
                appliedFilters.value = cloneDeep(
                  z
                    .object(
                      Object.entries(defaultAppliedFilters).reduce<Record<string, Schema>>(
                        (acc, [key, value]) => {
                          // Multi select
                          if (Array.isArray(value)) {
                            acc[key] = z
                              .array(
                                z.string().or(z.object({ value: z.string(), label: z.string() })),
                              )
                              .catch(value as string[]);
                            return acc;
                          }

                          // Flag
                          if (value === null || typeof value === 'boolean') {
                            acc[key] = z.boolean().nullable().catch(value);
                            return acc;
                          }

                          return acc;
                        },
                        {},
                      ),
                    )
                    .parse(persistedState.value.appliedFilters) as InventoriesFilter,
                );
              } catch (error) {
                // do nothing
              }
            }
          }

          fetching.value = false;
          fetched.value = true;
          fetchingPromise = null;
        })();
      }

      return fetchingPromise;
    }

    const persistingValue = computed(() => ({
      columnsVisibility: { ...columnsVisibility.value },
      columnsWidth: { ...userColumnsWidth.value },
      columnsOrder: [...columnsOrder.value],
      pinnedColumns: [...pinnedColumns.value],
      sorting: { ...sorting.value },
      appliedFilters: { ...appliedFilters.value },
    }));

    useUiStateAutoPersist({
      enabled: fetched,
      value: persistingValue,
      key: UI_STATES_KEY,
    });

    return {
      fetching,
      fetched,
      fetch,
      reset,
      resetColumnsSettings,
      columnsVisibility,
      columnsWidth,
      visibleColumns,
      columnsOrder,
      pinnedColumns,
      sorting,
      appliedFilters,
      columnsSettingsTouched,
      handleColumnResize,
      resetSorting,
    };
  },
);
