import type { Plugin } from 'vue';
import type { Router } from 'vue-router';
import { useEventBus } from '../../app';
import { useRecentlyVisitedStore } from './stores';

interface Options {
  router: Router;
}

export function createRecentlyVisited(options: Options): Plugin {
  return {
    install() {
      const { router } = options;
      const eventBus = useEventBus();
      const store = useRecentlyVisitedStore();

      router.afterEach((to) => {
        if (to.meta.trackVisit) {
          store.add(to);
        }
      });

      eventBus.on('appInitialized', async () => {
        await store.fetch();
        // Remove invalid records
        store.records = store.records.filter(({ path }) => !!router.resolve(path));
      });
    },
  };
}

export * from './stores';
export * from './components';
