import { ref, computed, toValue, watch, type MaybeRefOrGetter } from 'vue';
import { useDebounceFn } from '@vueuse/core';
import { isEqual } from 'lodash-es';
import { useUiStatesApi } from '../services';

interface UseUiStateAutoPersistOptions {
  enabled?: MaybeRefOrGetter<boolean>;
  key: string;
  value: MaybeRefOrGetter<any>;
}

export function useUiStateAutoPersist(options: UseUiStateAutoPersistOptions) {
  const api = useUiStatesApi();

  const enabled = computed(() => toValue(options.enabled) ?? true);
  const value = computed(() => toValue(options.value));
  const active = ref(false);

  async function persist() {
    if (!active.value) {
      return;
    }

    await api.saveUiState({
      key: options.key,
      value: value.value,
    });
  }

  const debouncedPersist = useDebounceFn(persist, 3000);

  watch(
    value,
    (value, oldValue) => {
      if (active.value && !isEqual(value, oldValue)) {
        debouncedPersist();
      }
    },
    {
      deep: true,
    },
  );

  watch(
    enabled,
    (value) => {
      active.value = value;
    },
    { immediate: true },
  );
}
