import { computed, unref } from 'vue';
import type { ComputedRef, MaybeRef } from 'vue';
import { getCurrencySymbol } from '../utils';
import { useI18n } from '../i18n';

export function useCurrencySymbol(currency: MaybeRef<string>): ComputedRef<string> {
  const { locale } = useI18n();

  return computed(() => {
    const currencyCode = unref(currency);

    if (currencyCode.length !== 3) {
      return '';
    } // TODO: warning in dev mode?

    return getCurrencySymbol(currencyCode, locale.value);
  });
}
