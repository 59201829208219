import { createPinia } from 'pinia';
import type { Pinia } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate';
import { config } from '../app/config';

export function createStore(): Pinia {
  const pinia = createPinia();

  pinia.use(
    createPersistedState({
      key: (id) => `${config.piniaStoreKeyPrefix}${id}`,
      debug: __DEV__,
    }),
  );

  return pinia;
}

export * from './account-store';
export * from './data';
export * from './i18n';
export * from './system-services-status';
export * from './user';
