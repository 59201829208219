import { RouterView } from 'vue-router';
import type { RouteRecordRaw, RouteLocation } from 'vue-router';
import { IconClipboardList } from '@tabler/icons-vue';
import { authGuard, notAuthGuard, createFeatureFlagGuard } from '../guards';
import { FeatureFlag } from '../../services/unleash';
import { useI18n } from '../../services/i18n';
import { useAccountStore } from '../../stores';
import { lineColors } from '../../modules/charts';
import { useSupersetKeyMeta, useSupersetDashboardsStore } from '../../modules/superset';
import { RoutesNames } from '../route-names';

async function getSupersetPageTitle(route: RouteLocation) {
  const store = useSupersetDashboardsStore();
  const { getTextForKey } = useSupersetKeyMeta();

  await store.fetch();

  const dashboard = store.dashboards.find(({ uuid }) => uuid === route.params.id);

  if (dashboard) {
    return getTextForKey(dashboard.name);
  }

  return null;
}

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('../../layouts/default-layout.vue'),
    meta: {
      guards: [authGuard],
    },
    children: [
      {
        path: '',
        component: () => import('../../modules/home/views/home-page.vue'),
        meta: {
          guards: [createFeatureFlagGuard(FeatureFlag.HomePage, '/inventory')],
        },
      },
      {
        path: '/replenishment',
        component: RouterView,
        redirect: '/replenishment/store',
        children: [
          {
            path: ':locationType',
            component: () => import('../../modules/replenishment/views/replenishment-index.vue'),
            props: (route) => ({ locationType: route.params.locationType }),
            redirect: (route) => `/replenishment/${route.params.locationType}/overview`,
            meta: {
              guards: [
                (from, to) => {
                  if (to.params.locationType === 'warehouse') {
                    const store = useAccountStore();
                    return store.whReplenishmentActive || '/replenishment';
                  }

                  if (to.params.locationType !== 'store') {
                    return '/replenishment';
                  }

                  return true;
                },
              ],
            },
            children: [
              {
                path: 'overview',
                component: () =>
                  import('../../modules/replenishment/views/replenishment-overview.vue'),
                props: (route) => ({ locationType: route.params.locationType }),
                meta: {
                  trackVisit: true,
                  title: (route) => {
                    const { t } = useI18n();
                    return route.params.locationType === 'warehouse'
                      ? t('modules.replenishment.messages.whReplenishmentOverview')
                      : t('modules.replenishment.messages.storeReplenishmentOverview');
                  },
                },
              },
              {
                path: 'locations',
                component: () =>
                  import('../../modules/replenishment/views/replenishment-locations.vue'),
                props: (route) => ({ locationType: route.params.locationType }),
                meta: {
                  trackVisit: true,
                  title: (route) => {
                    const { t } = useI18n();
                    return route.params.locationType === 'warehouse'
                      ? t('modules.replenishment.messages.whReplenishmentLocations')
                      : t('modules.replenishment.messages.storeReplenishmentLocations');
                  },
                },
              },
              {
                path: 'products',
                component: () =>
                  import('../../modules/replenishment/views/replenishment-products.vue'),
                props: (route) => ({ locationType: route.params.locationType }),
                meta: {
                  trackVisit: true,
                  title: (route) => {
                    const { t } = useI18n();
                    return route.params.locationType === 'warehouse'
                      ? t('modules.replenishment.messages.whReplenishmentProducts')
                      : t('modules.replenishment.messages.storeReplenishmentProducts');
                  },
                },
              },
              {
                path: 'inventories',
                component: () =>
                  import('../../modules/replenishment/views/replenishment-inventories.vue'),
                props: (route) => ({ locationType: route.params.locationType }),
                meta: {
                  trackVisit: true,
                  title: (route) => {
                    const { t } = useI18n();
                    return route.params.locationType === 'warehouse'
                      ? t('modules.replenishment.messages.whReplenishmentInventories')
                      : t('modules.replenishment.messages.storeReplenishmentInventories');
                  },
                },
              },
            ],
          },
        ],
      },
      {
        path: '/analytics',
        component: () => import('../../views/analytics/analytics-index.vue'),
        children: [
          {
            path: 'dashboards/:id',
            component: () => import('../../views/analytics/analytics-dashboard.vue'),
            meta: {
              trackVisit: true,
              title: getSupersetPageTitle,
            },
          },
        ],
      },
      {
        path: '/planning-assistant',
        component: () => import('../../views/analytics/analytics-index.vue'),
        meta: {
          guards: [createFeatureFlagGuard(FeatureFlag.AnalyticsPlanningAssistant)],
        },
        children: [
          {
            path: 'dashboards/:id',
            component: () => import('../../views/analytics/analytics-dashboard.vue'),
            props: {
              icon: IconClipboardList,
              iconBackground: lineColors.celticBlue,
            },
            meta: {
              trackVisit: true,
              title: getSupersetPageTitle,
            },
          },
        ],
      },
      {
        path: '/beta-reports',
        component: () => import('../../views/analytics/analytics-index.vue'),
        meta: {
          guards: [createFeatureFlagGuard(FeatureFlag.AnalyticsBetaReports)],
        },
        children: [
          {
            path: 'dashboards/:id',
            component: () => import('../../views/analytics/analytics-dashboard.vue'),
            props: {
              icon: IconClipboardList,
              iconBackground: lineColors.celticBlue,
            },
            meta: {
              trackVisit: true,
              title: getSupersetPageTitle,
            },
          },
        ],
      },
      {
        path: '/analytics-embedded',
        component: () => import('../../views/analytics/analytics-index.vue'),
        children: [
          {
            path: 'dashboards/:id',
            component: () => import('../../views/analytics/analytics-dashboard-embedded.vue'),
          },
        ],
      },
      {
        path: '/inventory',
        component: RouterView,
        children: [
          {
            path: '',
            component: () => import('../../modules/inventory/views/inventory-index.vue'),
            redirect: '/inventory/location',
            children: [
              {
                name: RoutesNames.InventoryLocations,
                path: 'location',
                component: () => import('../../modules/inventory/views/inventory-location.vue'),
                meta: {
                  trackVisit: true,
                  title: () => {
                    const { t } = useI18n();
                    return t('modules.inventory.messages.inventoryLocations');
                  },
                },
              },
              {
                name: RoutesNames.InventorySkus,
                path: 'sku',
                component: () => import('../../modules/inventory/views/inventory-sku.vue'),
                meta: {
                  trackVisit: true,
                  title: () => {
                    const { t } = useI18n();
                    return t('modules.inventory.messages.inventorySkus');
                  },
                },
              },
              {
                name: RoutesNames.InventoryInventories,
                path: 'inventories',
                component: () => import('../../modules/inventory/views/inventory-inventories.vue'),
                meta: {
                  trackVisit: true,
                  title: () => {
                    const { t } = useI18n();
                    return t('modules.inventory.messages.inventoryInventories');
                  },
                },
              },
              {
                name: RoutesNames.InventoryTransactions,
                path: 'transactions',
                component: () => import('../../modules/inventory/views/inventory-transactions.vue'),
                meta: {
                  trackVisit: true,
                  title: () => {
                    const { t } = useI18n();
                    return t('modules.inventory.messages.inventoryTransactions');
                  },
                },
              },
            ],
          },
          {
            path: 'inventories/:id',
            component: () => import('../../modules/sku-chart/views/sku-chart.vue'),
            props: {
              breadcrumbs: ['/inventory', '/inventory/inventories'],
            },
          },
          {
            path: 'location/avoid-replenishment-upload',
            component: () =>
              import('../../modules/inventory/views/inventory-avoid-replenishment-upload.vue'),
            props: () => {
              const { t } = useI18n();

              return {
                breadcrumbs: [
                  {
                    label: t('modules.inventory.messages.breadcrumbInventory'),
                    href: '/inventory',
                  },
                  {
                    label: t('modules.inventory.messages.breadcrumbLocations'),
                    href: '/inventory/location',
                  },
                  {
                    label: t(
                      'modules.inventory.messages.avoidReplenishmentSettingsUploadingPageTitle',
                    ),
                    href: '/inventory/location/avoid-replenishment-upload',
                  },
                ],
              };
            },
            meta: {
              trackVisit: true,
              title: () => {
                const { t } = useI18n();
                return t('modules.inventory.messages.locationsReplenishmentStatusUpload');
              },
            },
          },
          {
            path: 'sku/avoid-replenishment-upload',
            component: () =>
              import('../../modules/inventory/views/inventory-avoid-replenishment-upload.vue'),
            props: () => {
              const { t } = useI18n();

              return {
                breadcrumbs: [
                  {
                    label: t('modules.inventory.messages.breadcrumbInventory'),
                    href: '/inventory',
                  },
                  {
                    label: t('modules.inventory.messages.breadcrumbSkus'),
                    href: '/inventory/sku',
                  },
                  {
                    label: t(
                      'modules.inventory.messages.avoidReplenishmentSettingsUploadingPageTitle',
                    ),
                    href: '/inventory/sku/avoid-replenishment-upload',
                  },
                ],
              };
            },
            meta: {
              trackVisit: true,
              title: () => {
                const { t } = useI18n();
                return t('modules.inventory.messages.skusReplenishmentStatusUpload');
              },
            },
          },
          {
            path: 'inventories/avoid-replenishment-upload',
            component: () =>
              import('../../modules/inventory/views/inventory-avoid-replenishment-upload.vue'),
            props: () => {
              const { t } = useI18n();

              return {
                breadcrumbs: [
                  {
                    label: t('modules.inventory.messages.breadcrumbInventory'),
                    href: '/inventory',
                  },
                  {
                    label: t('modules.inventory.messages.breadcrumbInventories'),
                    href: '/inventory/inventories',
                  },
                  {
                    label: t(
                      'modules.inventory.messages.avoidReplenishmentSettingsUploadingPageTitle',
                    ),
                    href: '/inventory/inventories/avoid-replenishment-upload',
                  },
                ],
              };
            },
            meta: {
              trackVisit: true,
              title: () => {
                const { t } = useI18n();
                return t('modules.inventory.messages.inventoriesReplenishmentStatusUpload');
              },
            },
          },
        ],
      },
      {
        path: '/store-transfer',
        component: RouterView,
        children: [
          {
            path: '',
            component: () => import('../../modules/store-transfer/views/store-transfer-index.vue'),
            redirect: '/store-transfer/recommendations',
            children: [
              {
                path: 'recommendations',
                component: () =>
                  import('../../modules/store-transfer/views/store-transfer-recommendations.vue'),
                redirect: '/store-transfer/recommendations/trips',
                children: [
                  {
                    name: RoutesNames.StoreTransferRecommendationsTrips,
                    path: 'trips',
                    component: () =>
                      import(
                        '../../modules/store-transfer/views/store-transfer-recommendations-trips.vue'
                      ),
                    meta: {
                      trackVisit: true,
                      title: () => {
                        const { t } = useI18n();
                        return t(
                          'modules.storeTransfer.messages.storeTransferRecommendationsTrips',
                        );
                      },
                    },
                  },
                  {
                    name: RoutesNames.StoreTransferRecommendationsProducts,
                    path: 'products',
                    component: () =>
                      import(
                        '../../modules/store-transfer/views/store-transfer-recommendations-products.vue'
                      ),
                    meta: {
                      trackVisit: true,
                      title: () => {
                        const { t } = useI18n();
                        return t(
                          'modules.storeTransfer.messages.storeTransferRecommendationsProducts',
                        );
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'recommendations/clusters/:clusterId',
            component: () =>
              import('../../modules/store-transfer/views/store-transfer-cluster-sku-trips.vue'),
            meta: { guards: [createFeatureFlagGuard(FeatureFlag.StoreTransferSkuTrips)] },
          },
        ],
      },
      {
        path: '/allocations',
        component: RouterView,
        meta: {
          guards: [createFeatureFlagGuard(FeatureFlag.Allocations)],
        },
        children: [
          {
            path: '',
            component: () => import('../../modules/allocations/views/allocations-index.vue'),
            redirect: '/allocations/pending',
            children: [
              {
                name: RoutesNames.AllocationsScheduled,
                path: 'scheduled',
                component: () =>
                  import('../../modules/allocations/views/allocations-scheduled.vue'),
                meta: {
                  trackVisit: true,
                  title: () => {
                    const { t } = useI18n();
                    return t('modules.allocations.messages.scheduledAllocations');
                  },
                },
              },
              {
                name: RoutesNames.AllocationsPending,
                path: 'pending',
                component: () => import('../../modules/allocations/views/allocations-pending.vue'),
                meta: {
                  trackVisit: true,
                  title: () => {
                    const { t } = useI18n();
                    return t('modules.allocations.messages.pendingAllocations');
                  },
                },
              },
              {
                name: RoutesNames.AllocationsApproved,
                path: 'approved',
                component: () => import('../../modules/allocations/views/allocations-approved.vue'),
                meta: {
                  trackVisit: true,
                  title: () => {
                    const { t } = useI18n();
                    return t('modules.allocations.messages.approvedAllocations');
                  },
                },
              },
            ],
          },
          {
            name: RoutesNames.AllocationsUpload,
            path: 'upload',
            component: () => import('../../modules/allocations/views/allocations-upload.vue'),
            meta: {
              trackVisit: true,
              title: () => {
                const { t } = useI18n();
                return t('modules.allocations.messages.allocationsUpload');
              },
            },
          },
        ],
      },
      {
        path: '/special-events',
        component: RouterView,
        children: [
          {
            path: '',
            redirect: '/special-events/pending',
            component: () => import('../../modules/special-events/views/special-events-index.vue'),
            children: [
              {
                name: RoutesNames.SpecialEventsPending,
                path: 'pending',
                component: () =>
                  import('../../modules/special-events/views/special-events-pending.vue'),
                meta: {
                  trackVisit: true,
                  title: () => {
                    const { t } = useI18n();
                    return t('modules.specialEvents.messages.pendingSpecialEvents');
                  },
                },
              },
              {
                name: RoutesNames.SpecialEventsUpcoming,
                path: 'upcoming',
                component: () =>
                  import('../../modules/special-events/views/special-events-upcoming.vue'),
                meta: {
                  trackVisit: true,
                  title: () => {
                    const { t } = useI18n();
                    return t('modules.specialEvents.messages.upcomingSpecialEvents');
                  },
                },
              },
              {
                name: RoutesNames.SpecialEventsApproved,
                path: 'approved',
                component: () =>
                  import('../../modules/special-events/views/special-events-approved.vue'),
                meta: {
                  trackVisit: true,
                  title: () => {
                    const { t } = useI18n();
                    return t('modules.specialEvents.messages.approvedSpecialEvents');
                  },
                },
              },
            ],
          },
          {
            name: RoutesNames.SpecialEventsAnalysis,
            path: 'analysis',
            component: () =>
              import('../../modules/special-events/views/special-events-analysis.vue'),
            meta: {
              trackVisit: true,
              title: () => {
                const { t } = useI18n();
                return t('globalNavigation.eventsAnalysis');
              },
            },
          },
          {
            path: ':id',
            component: () => import('../../modules/special-events/views/special-event.vue'),
          },
          {
            path: ':id/analysis',
            component: () =>
              import('../../modules/special-events/views/special-event-analysis.vue'),
            props: (route) => ({ id: route.params.id }),
          },
          {
            name: RoutesNames.SpecialEventsUpload,
            path: 'upload',
            component: () => import('../../modules/special-events/views/special-events-upload.vue'),
            meta: {
              trackVisit: true,
              title: () => {
                const { t } = useI18n();
                return t('globalNavigation.downloadCenter');
              },
            },
          },
        ],
      },
      {
        path: '/sku-chart/:id',
        component: () => import('../../modules/sku-chart/views/sku-chart.vue'),
      },
      {
        name: RoutesNames.DownloadsCenter,
        path: '/downloads',
        component: () => import('../../modules/downloads/views/download-center.vue'),
        meta: {
          trackVisit: true,
          title: () => {
            const { t } = useI18n();
            return t('globalNavigation.downloadCenter');
          },
        },
      },
    ],
  },
  {
    path: '/auth',
    name: 'auth',
    meta: {
      guards: [notAuthGuard],
    },
    component: () => import('../../views/auth-page.vue'),
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('../../views/not-found.vue'),
  },
];
