export enum FeatureFlag {
  AnalyticsPlanningAssistant = 'analytics.planningAssistant',
  AnalyticsBetaReports = 'analytics.betaReports',
  Allocations = 'allocations',
  InventoryAvoidReplenishmentUpload = 'inventory.avoidReplenishmentUpload',
  StoreTransferSkuTrips = 'storeTransfer.skuTrips',
  ReplenshimentProactiveInsights = 'replenshiment.proactiveInsights',
  NewFilters = 'newFilters',
  HomePage = 'homePage',
  SwitchAccountButton = 'switchAccountButton',
}
