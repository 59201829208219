import { inject, getCurrentInstance, type Plugin, type InjectionKey } from 'vue';
import mitt, { type Emitter } from 'mitt';

type Events = {
  appInitialized: void;
};

export const EVENT_BUS_SYMBOL: InjectionKey<Emitter<Events>> = Symbol(__DEV__ ? 'EventBus' : '');

let emitter: Emitter<Events>;

export function createEventBus(): Plugin {
  return {
    install(app) {
      emitter = mitt<Events>();
      app.provide(EVENT_BUS_SYMBOL, emitter);
    },
  };
}

export function useEventBus(): Emitter<Events> {
  const eventBus = getCurrentInstance() ? inject(EVENT_BUS_SYMBOL) : emitter;

  if (!eventBus) {
    throw new Error(
      'EventBus plugin is not installed. Make sure to call `app.use(createEventBus())`.',
    );
  }

  return eventBus;
}
