interface Config {
  version: string;
  environment: string;
  baseUrl: string;
  apiUrl: string;
  keycloak: {
    url: string;
    realm: string;
    clientId: string;
    enableLogging?: boolean;
    silentCheckSsoRedirectUri: string;
  };
  meilisearch: {
    host: string;
  };
  metaMeilisearch: {
    host: string;
  };
  superset: {
    baseUrl: string;
    metastoreBaseUrl: string;
  };
  heap: {
    enabled: boolean;
    appId: string;
  };
  sentry: {
    enabled: boolean;
    environment: string;
    dsn: string;
  };
  unleash: {
    enabled: boolean;
    url: string;
    clientKey: string;
    appName: string;
    refreshInterval: number;
  };
  piniaStoreKeyPrefix: string;
}

const env = import.meta.env;

export const config: Config = {
  version: env.ONEBEAT_APP_VERSION,
  environment: env.ONEBEAT_APP_ENVIRONMENT,
  baseUrl: '/',
  apiUrl: env.ONEBEAT_APP_API_URL,
  keycloak: {
    url: env.ONEBEAT_APP_KEYCLOAK_URL,
    realm: env.ONEBEAT_APP_KEYCLOAK_REALM,
    clientId: env.ONEBEAT_APP_KEYCLOAK_CLIENT_ID,
    enableLogging: __DEV__,
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
  },
  meilisearch: {
    host: env.ONEBEAT_APP_MEILISEARCH_HOST,
  },
  metaMeilisearch: {
    host: env.ONEBEAT_APP_META_MEILISEARCH_HOST,
  },
  superset: {
    baseUrl: env.ONEBEAT_APP_SUPERSET_BASE_URL,
    metastoreBaseUrl: env.ONEBEAT_APP_SUPERSET_METASTORE_BASE_URL,
  },
  heap: {
    enabled: !!env.ONEBEAT_APP_HEAP_APP_ID,
    appId: env.ONEBEAT_APP_HEAP_APP_ID,
  },
  sentry: {
    enabled: !!env.ONEBEAT_APP_SENTRY_DSN,
    environment: env.ONEBEAT_APP_SENTRY_ENVIRONMENT,
    dsn: env.ONEBEAT_APP_SENTRY_DSN,
  },
  unleash: {
    enabled: !!(env.ONEBEAT_APP_UNLEASH_URL && env.ONEBEAT_APP_UNLEASH_CLIENT_KEY),
    url: env.ONEBEAT_APP_UNLEASH_URL,
    clientKey: env.ONEBEAT_APP_UNLEASH_CLIENT_KEY,
    appName: 'onebeat-app',
    refreshInterval: 30,
  },
  piniaStoreKeyPrefix: 'onebeat:',
};
