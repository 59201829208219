import {
  ref,
  computed,
  toValue,
  watch,
  type Ref,
  type ComputedRef,
  type WritableComputedRef,
  type MaybeRefOrGetter,
} from 'vue';
import { Schema, z } from 'zod';
import { intersection, union, isEqual, pick } from 'lodash-es';
import { Sorting } from '../shared/types';

type ColumnsVisibility = Record<string, boolean>;
type ColumnsWidth = Record<string, number>;

interface UseDataTableSettingsOptions {
  defaultColumnsVisibility?: MaybeRefOrGetter<ColumnsVisibility>;
  defaultColumnsWidth?: MaybeRefOrGetter<ColumnsWidth>;
  defaultColumnsOrder?: MaybeRefOrGetter<string[]>;
  defaultPinnedColumns?: MaybeRefOrGetter<string[]>;
  defaultSorting?: MaybeRefOrGetter<Sorting>;
  customColumns?: MaybeRefOrGetter<string[]>;
}

interface PersistedState {
  columnsVisibility?: ColumnsVisibility;
  columnsWidth?: ColumnsWidth;
  pinnedColumnsOrder?: string[]; // TODO: deprecated, remove
  columnsOrder?: string[];
  pinnedColumns?: string[];
  sorting?: Sorting;
}

interface ColumnResizePayload {
  key: string;
  width: number;
}

interface UseDataTableSettingsReturn {
  columnsVisibility: Ref<ColumnsVisibility>;
  columnsWidth: Ref<ColumnsWidth>;
  userColumnsWidth: ComputedRef<ColumnsWidth>;
  columnsOrder: Ref<string[]>;
  pinnedColumns: Ref<string[]>;
  sorting: Ref<Sorting>;
  visibleColumns: WritableComputedRef<string[]>;
  columnsSettingsTouched: ComputedRef<boolean>;
  resetColumnsSettings: () => void;
  applyPersistedState: (persistedState: PersistedState) => void;
  handleColumnResize: (payload: ColumnResizePayload) => void;
  resetSorting: () => void;
}

// TODO: check all cases with possible changes in columns structure
// TODO: do not persist default states?

export function useDataTableSettings(
  options: UseDataTableSettingsOptions,
): UseDataTableSettingsReturn {
  const customColumns = computed(() => toValue(options.customColumns) ?? []);
  const defaultColumnsVisibility = computed(() => {
    const result = toValue(options.defaultColumnsVisibility) ?? {};

    customColumns.value.forEach((item) => {
      result[item] = true;
    });

    return result;
  });
  const defaultColumnsWidth = computed(() => {
    const result = toValue(options.defaultColumnsWidth) ?? {};

    customColumns.value.forEach((item) => {
      result[item] = 192;
    });

    return result;
  });
  const defaultColumnsOrder = computed(() => {
    const result = toValue(options.defaultColumnsOrder) ?? [];

    result.push(...customColumns.value);

    return result;
  });
  const defaultPinnedColumns = computed(() => toValue(options.defaultPinnedColumns) ?? []);
  const defaultSorting = computed<Sorting>(
    () => toValue(options.defaultSorting) ?? { sortBy: null, sortOrder: 'asc' },
  );

  const allColumns = computed(() => Object.keys(defaultColumnsVisibility.value));

  const columnsVisibility = ref<ColumnsVisibility>({ ...defaultColumnsVisibility.value });
  const columnsWidth = ref<ColumnsWidth>({ ...defaultColumnsWidth.value });
  const columnsOrder = ref<string[]>([...defaultColumnsOrder.value]);
  const pinnedColumns = ref<string[]>([...defaultPinnedColumns.value]);
  const sorting = ref<Sorting>({ ...defaultSorting.value });

  watch(customColumns, () => {
    columnsVisibility.value = {
      ...defaultColumnsVisibility.value,
      ...pick(columnsVisibility.value, Object.keys(defaultColumnsVisibility.value)),
    };

    columnsWidth.value = {
      ...defaultColumnsWidth.value,
      ...pick(columnsWidth.value, Object.keys(defaultColumnsWidth.value)),
    };

    columnsOrder.value = union(
      intersection(columnsOrder.value, defaultColumnsOrder.value),
      defaultColumnsOrder.value,
    );
  });

  const userColumnsWidth = computed(() => {
    return Object.entries(columnsWidth.value).reduce<Record<string, number>>(
      (acc, [key, value]) => {
        if (value !== defaultColumnsWidth.value[key]) {
          acc[key] = value;
        }

        return acc;
      },
      {},
    );
  });

  const visibleColumns = computed({
    get() {
      return Object.entries(columnsVisibility.value).reduce<string[]>((acc, [key, value]) => {
        if (value) {
          acc.push(key);
        }

        return acc;
      }, []);
    },
    set(value) {
      const keys = new Set(value);

      Object.keys(columnsVisibility.value).forEach((key) => {
        columnsVisibility.value[key] = keys.has(key);
      });
    },
  });

  const columnsSettingsTouched = computed(
    () =>
      !isEqual(defaultColumnsVisibility.value, columnsVisibility.value) ||
      !isEqual(defaultColumnsOrder.value, columnsOrder.value) ||
      !isEqual(defaultPinnedColumns.value, pinnedColumns.value) ||
      !isEqual(defaultColumnsWidth.value, columnsWidth.value),
  );

  function handleColumnResize({ key, width }: ColumnResizePayload) {
    columnsWidth.value = {
      ...columnsWidth.value,
      [key]: width,
    };
  }

  function resetColumnsSettings() {
    columnsVisibility.value = {
      ...defaultColumnsVisibility.value,
    };
    columnsOrder.value = [...defaultColumnsOrder.value];
    pinnedColumns.value = [...defaultPinnedColumns.value];
    columnsWidth.value = { ...defaultColumnsWidth.value };
  }

  function resetSorting() {
    sorting.value = { ...defaultSorting.value };
  }

  function applyPersistedState(persistedState: PersistedState) {
    if (persistedState.columnsVisibility) {
      try {
        columnsVisibility.value = z
          .object(
            Object.keys(defaultColumnsVisibility.value).reduce<Record<string, Schema>>(
              (acc, key) => {
                acc[key] = z.boolean().catch(defaultColumnsVisibility.value[key]);
                return acc;
              },
              {},
            ),
          )
          .parse(persistedState.columnsVisibility);
      } catch (error) {
        // do nothing
      }
    }

    if (persistedState.columnsWidth) {
      try {
        columnsWidth.value = z
          .object(
            Object.keys(defaultColumnsWidth.value).reduce<Record<string, Schema>>((acc, key) => {
              acc[key] = z.number().catch(defaultColumnsWidth.value[key]);
              return acc;
            }, {}),
          )
          .parse(persistedState.columnsWidth);
      } catch (error) {
        // do nothing
      }
    }

    // TODO: remove pinnedColumnsOrder
    const persistedColumnsOrder = [
      ...(persistedState.pinnedColumnsOrder ?? []),
      ...(persistedState.columnsOrder ?? []),
    ];

    if (persistedColumnsOrder.length) {
      try {
        z.array(z.string()).parse(persistedColumnsOrder);

        columnsOrder.value = union(
          intersection(persistedColumnsOrder, defaultColumnsOrder.value),
          defaultColumnsOrder.value,
        );
      } catch (error) {
        // do nothing
      }
    }

    if (persistedState.pinnedColumns) {
      try {
        z.array(z.string()).parse(persistedState.pinnedColumns);
        pinnedColumns.value = persistedState.pinnedColumns;
      } catch (error) {
        // do nothing
      }
    }

    if (persistedState.sorting) {
      try {
        sorting.value = z
          .object({
            sortBy: z
              .string()
              .nullable()
              .refine((value) => {
                return value === null || allColumns.value.includes(value);
              }),
            sortOrder: z.enum(['asc', 'desc']),
          })
          .catch({ ...defaultSorting.value })
          .parse(persistedState.sorting);
      } catch (error) {
        // do nothing
      }
    }
  }

  watch([defaultColumnsVisibility, defaultColumnsOrder, defaultPinnedColumns], () => {
    resetColumnsSettings();
  });

  return {
    columnsVisibility,
    columnsWidth,
    columnsOrder,
    visibleColumns,
    pinnedColumns,
    sorting,
    resetColumnsSettings,
    applyPersistedState,
    columnsSettingsTouched,
    resetSorting,
    handleColumnResize,
    userColumnsWidth,
  };
}
