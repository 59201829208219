import { defineStore } from 'pinia';
import { z } from 'zod';
import { computed, ref, watch } from 'vue';
import { useDebounceFn, whenever } from '@vueuse/core';
import { cloneDeep, isEqual } from 'lodash-es';
import { StoreNames } from '../../../shared/store-names';
import { useUiStatesApi } from '../../ui-states';
import { useTableColumnsSettingsStore } from '../../../composables';
import type { ReplenishmentLocationsFilterState } from '../interfaces';
import { Sorting } from '../../../shared/types';

enum Columns {
  Name = 'name',
  ExternalId = 'external_id',
  LocationType = 'location_type',
  City = 'city',
  Sold = 'sold',
  Coverage = 'coverage',
  Replenishment = 'replenishment',
  ExpectedCoverage = 'expected_coverage',
  AvgDailySales = 'avg_daily_sales',
  SourceLocations = 'source_locations',
  Region = 'region',
  TotalProducts = 'total_products',
  TotalSkus = 'total_skus',
  ReplenishmentTime = 'replenishment_time',
  Classifications = 'classifications',
  Brands = 'brands',
  SoldDepleted = 'sold_depleted',
  CoverageDepleted = 'coverage_depleted',
  AvgDailySalesDepleted = 'avg_daily_sales_depleted',
  Constraints = 'constraints',
  WeeklyInventoryNeededRatio = 'weekly_inventory_needed_ratio',
}

const replenishmentLocationsFilterSchema = z.object({
  region: z.tuple([z.enum(['in', 'nin']), z.array(z.string())]),
  city: z.tuple([z.enum(['in', 'nin']), z.array(z.string())]),
  weekly_inventory_needed_ratio: z.tuple([
    z.enum(['in_range', 'nin_range']),
    z.tuple([z.union([z.number(), z.null()]), z.union([z.number(), z.null()])]),
  ]),
});

export const useReplenishmentLocationsPageStore = defineStore(
  StoreNames.ReplenishmentLocationsPage,
  () => {
    const defaultSorting: Sorting = {
      sortBy: null,
      sortOrder: 'asc',
    };

    const defaultAppliedFilters: ReplenishmentLocationsFilterState = {
      region: ['in', []],
      city: ['in', []],
      weekly_inventory_needed_ratio: ['in_range', [null, null]],
    };

    const sorting = ref<Sorting>({ ...defaultSorting });
    const appliedFilters = ref<ReplenishmentLocationsFilterState>(cloneDeep(defaultAppliedFilters));

    const {
      columnsVisibility,
      columnsOrder,
      pinnedColumnsOrder,
      allColumns,
      visibleColumns,
      resetColumnsSettings,
      applyPersistedColumnsSettings,
      columnsSettingsTouched,
    } = useTableColumnsSettingsStore({
      defaultColumnsVisibility: {
        [Columns.Name]: true,
        [Columns.ExternalId]: false,
        [Columns.LocationType]: false,
        [Columns.City]: false,
        [Columns.Sold]: true,
        [Columns.Coverage]: false,
        [Columns.Replenishment]: true,
        [Columns.ExpectedCoverage]: false,
        [Columns.AvgDailySales]: false,
        [Columns.SourceLocations]: false,
        [Columns.Region]: false,
        [Columns.TotalProducts]: true,
        [Columns.TotalSkus]: true,
        [Columns.ReplenishmentTime]: false,
        [Columns.Classifications]: false,
        [Columns.Brands]: false,
        [Columns.SoldDepleted]: false,
        [Columns.CoverageDepleted]: false,
        [Columns.AvgDailySalesDepleted]: false,
        [Columns.Constraints]: true,
        [Columns.WeeklyInventoryNeededRatio]: false,
      },
      defaultColumnsOrder: [
        Columns.LocationType,
        Columns.City,
        Columns.Sold,
        Columns.Coverage,
        Columns.Replenishment,
        Columns.WeeklyInventoryNeededRatio,
        Columns.ExpectedCoverage,
        Columns.AvgDailySales,
        Columns.SourceLocations,
        Columns.Region,
        Columns.TotalProducts,
        Columns.TotalSkus,
        Columns.ReplenishmentTime,
        Columns.Classifications,
        Columns.Brands,
        Columns.SoldDepleted,
        Columns.CoverageDepleted,
        Columns.AvgDailySalesDepleted,
        Columns.Constraints,
      ],
      defaultPinnedColumnsOrder: [Columns.Name, Columns.ExternalId],
    });

    function reset() {
      resetColumnsSettings();
      sorting.value = { ...defaultSorting };
      appliedFilters.value = cloneDeep(defaultAppliedFilters);
    }

    const api = useUiStatesApi();

    const fetching = ref(false);
    const fetched = ref(false);

    let fetchingPromise: Promise<void> | null = null;

    async function fetch(force = false) {
      if (fetched.value && !force) {
        return fetchingPromise ?? Promise.resolve();
      }

      if (!fetchingPromise) {
        fetchingPromise = (async () => {
          fetching.value = true;

          const persistedState = await api
            .getUiStates({ key: StoreNames.ReplenishmentLocationsPage })
            .then(({ data }) => data.data[0]);

          reset();

          // Apply persisted state

          if (persistedState) {
            applyPersistedColumnsSettings({
              columnsVisibility: persistedState.value.columnsVisibility,
              columnsOrder: persistedState.value.columnsOrder,
              pinnedColumnsOrder: persistedState.value.pinnedColumnsOrder,
            });

            if (persistedState.value.sorting) {
              try {
                sorting.value = z
                  .object({
                    sortBy: z
                      .string()
                      .nullable()
                      .refine((value) => {
                        return value === null || allColumns.value.includes(value);
                      }),
                    sortOrder: z.enum(['asc', 'desc']),
                  })
                  .catch({ ...defaultSorting })
                  .parse(persistedState.value.sorting);
              } catch (error) {
                // do nothing
              }
            }

            if (persistedState.value.appliedFilters) {
              try {
                appliedFilters.value = replenishmentLocationsFilterSchema.parse(
                  persistedState.value.appliedFilters,
                );
              } catch (error) {
                appliedFilters.value = cloneDeep(defaultAppliedFilters);
              }
            }
          }

          fetching.value = false;
          fetched.value = true;
          fetchingPromise = null;
        })();
      }

      return fetchingPromise;
    }

    async function persist() {
      await api.saveUiState({
        key: StoreNames.ReplenishmentLocationsPage,
        value: {
          columnsVisibility: columnsVisibility.value,
          columnsOrder: columnsOrder.value,
          pinnedColumnsOrder: pinnedColumnsOrder.value,
          sorting: sorting.value,
          appliedFilters: appliedFilters.value,
        },
      });
    }

    const debouncedPersist = useDebounceFn(persist, 3000);
    const autoPersistEnabled = ref(false);

    const persistingValue = computed(() => ({
      columnsVisibility: { ...columnsVisibility.value },
      columnsOrder: [...columnsOrder.value],
      pinnedColumnsOrder: [...pinnedColumnsOrder.value],
      sorting: { ...sorting.value },
      appliedFilters: { ...appliedFilters.value },
    }));

    watch(
      persistingValue,
      (value, oldValue) => {
        if (autoPersistEnabled.value && !isEqual(value, oldValue)) {
          debouncedPersist();
        }
      },
      {
        deep: true,
      },
    );

    whenever(
      fetched,
      () => {
        autoPersistEnabled.value = true;
      },
      { once: true },
    );

    return {
      fetching,
      fetched,
      fetch,
      reset,
      resetColumnsSettings,
      columnsVisibility,
      visibleColumns,
      columnsOrder,
      pinnedColumnsOrder,
      sorting,
      appliedFilters,
      columnsSettingsTouched,
    };
  },
);
