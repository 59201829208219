import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useApi } from '../services/api';
import { StoreNames } from '../shared/store-names';

interface CustomDataSchema {
  [scope: string]: [{ key: string; type: 'string' | 'float' | 'boolean' | 'datetime' }];
}

export const useAccountStore = defineStore(StoreNames.Account, () => {
  const id = ref('');
  const name = ref('');
  const locale = ref('');
  const currency = ref('');
  const whReplenishmentActive = ref(false);
  const timezone = ref('UTC');
  const customDataSchema = ref<CustomDataSchema>({});
  const accountLastReplenishmentDate = ref('');
  const promotionAiEnabled = ref(false);

  const api = useApi();

  const fetching = ref(false);
  const fetched = ref(false);

  let fetchingPromise: Promise<void> | null = null;

  async function fetch(force = false) {
    if (fetched.value && !force) {
      return fetchingPromise ?? Promise.resolve();
    }

    if (!fetchingPromise) {
      fetchingPromise = (async () => {
        fetching.value = true;

        const { data } = await api.getAccountSettings();

        id.value = data.id;
        name.value = data.name;
        locale.value = data.locale;
        currency.value = data.currency;
        timezone.value = data.timezone_name || 'UTC';
        whReplenishmentActive.value = data.wh_replenishment_active;
        customDataSchema.value = data.custom_data_schema ?? {};
        accountLastReplenishmentDate.value = data.account_last_replenishment_date;
        promotionAiEnabled.value = data.promotion_ai_enabled;

        fetching.value = false;
        fetched.value = true;
        fetchingPromise = null;
      })();
    }

    return fetchingPromise;
  }

  function clear() {
    name.value = '';
    locale.value = '';
    currency.value = '';
    timezone.value = 'UTC';
    whReplenishmentActive.value = false;
    customDataSchema.value = {};
    promotionAiEnabled.value = false;
  }

  return {
    id,
    name,
    locale,
    currency,
    whReplenishmentActive,
    timezone,
    customDataSchema,
    accountLastReplenishmentDate,
    promotionAiEnabled,
    fetching,
    fetch,
    fetched,
    clear,
  };
});
