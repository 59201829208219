import { ref } from 'vue';
import { defineStore } from 'pinia';
import { getDecimalSeparator, getThousandSeparator, getTextDirection } from '../services/i18n';
import { StoreNames } from '../shared/store-names';

export const useI18nStore = defineStore(
  StoreNames.I18n,
  () => {
    const locale = ref('en');
    const textDirection = ref<'ltr' | 'rtl'>('ltr');
    const decimalSeparator = ref('');
    const thousandSeparator = ref('');

    function setLocale(newLocale: string) {
      locale.value = newLocale;
      decimalSeparator.value = getDecimalSeparator(newLocale);
      thousandSeparator.value = getThousandSeparator(newLocale);
      textDirection.value = getTextDirection(newLocale);
    }

    return {
      locale,
      textDirection,
      decimalSeparator,
      thousandSeparator,
      setLocale,
    };
  },
  {
    persist: true,
  },
);
