import { defineStore } from 'pinia';
import { useApi } from '../services/api';
import { StoreNames } from '../shared/store-names';

export const useUserStore = defineStore(StoreNames.User, {
  state: () => ({
    email: '',
    email_verified: false,
    family_name: '',
    given_name: '',
    name: '',
    preferred_username: '',
    id: '',
  }),
  actions: {
    async fetch(): Promise<void> {
      const api = useApi();

      const data = await api.auth.getUserInfo();

      if (!data) {
        return;
      }

      this.email = data.email;
      this.email_verified = data.email_verified;
      this.family_name = data.family_name;
      this.given_name = data.given_name;
      this.name = data.name;
      this.preferred_username = data.preferred_username;
      this.id = data.sub;
    },
    async clear(): Promise<void> {
      this.$reset();
    },
  },
});
