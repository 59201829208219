export enum StoreNames {
  Account = 'account',
  AllocationsScheduledPageStore = 'allocations-scheduled-page-store',
  AllocationsApprovedPageStore = 'allocations-approved-page-store',
  AllocationsPendingPageStore = 'allocations-pending-page-store',
  AllocationsSettingsStore = 'allocations-settings-store',
  AllocationsStore = 'allocations',
  Data = 'data',
  I18n = 'i18n',
  InventoryAvoidReplenishmentStatus = 'inventory-avoid-replenishment-status',
  InventoryInventoriesPage = 'inventory-inventories-page',
  InventoryLocationsPage = 'inventory-locations-page',
  InventorySkusPage = 'inventory-skus-page',
  ReplenishmentLocationsPage = 'replenishment-locations-page',
  ReplenishmentOverviewPage = 'replenishment-overview-page',
  ReplenishmentInventoriesPage = 'replenishment-inventories-page',
  ReplenishmentLocationDetailsPage = 'replenishment-location-details-page',
  ReplenishmentProductDetailsPage = 'replenishment-product-details-page',
  SkuChart = 'sku-chart',
  SpecialEventPageStore = 'special-event-page',
  SpecialEventsStore = 'special-events',
  SpecialEventsApprovedPageStore = 'special-events-approved-page',
  SpecialEventsUpcomingPageStore = 'special-events-upcoming-page',
  SpecialEventsPendingPageStore = 'special-events-pending-page',
  Superset = 'superset',
  SupersetKeyMeta = 'superset-key-meta',
  SupersetDashboards = 'superset-dashboards',
  SystemServicesStatus = 'system-services-status',
  User = 'user',
  StoreTransferSettings = 'store-transfer-settings',
  StoreTransferTripDetails = 'store-transfer-trip-details',
  StoreTransferSkuTripsPage = 'store-transfer-sku-trips-page',
  DownloadsWidget = 'downloads-widget',
}
