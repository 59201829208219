export enum InventoriesColumns {
  LocationName = 'location_name',
  LocationExternalId = 'location_external_id',
  SkuName = 'sku_name',
  SkuExternalId = 'sku_external_id',
  SkuDescription = 'sku_description',
  LocationDescription = 'location_description',
  ProductName = 'product_name',
  ProductExternalId = 'product_external_id',
  Size = 'size',
  SourceLocationExternalId = 'source_location_external_id',
  Styles = 'styles',
  Colors = 'colors',
  Categories = 'categories',
  DepartmentName = 'department_name',
  DepartmentExternalId = 'department_external_id',
  Brands = 'brands',
  Seasons = 'seasons',
  Markets = 'markets',
  Cost = 'cost',
  Price = 'price',
  RegionName = 'region_name',
  CityName = 'city_name',
  LocationType = 'location_type',
  Classifications = 'classifications',
  SourceLocations = 'source_locations',
  SiteQty = 'site_qty',
  TransitQty = 'transit_qty',
  AtWh = 'at_wh',
  LastStatusDate = 'last_status_date',
  ReservedQty = 'reserved_qty',
  SaleRate = 'sale_rate',
  OptimalStock = 'optimal_stock',
  MinTarget = 'min_target',
  MaxTarget = 'max_target',
  Coverage = 'coverage',
  CreatedAt = 'created_at',
  AvoidReplenishment = 'avoid_replenishment',
  AvoidReplenishmentForLocation = 'location_avoid_replenishment',
  AvoidReplenishmentForSku = 'sku_avoid_replenishment',
}
