export enum SkusColumns {
  Name = 'name',
  ExternalId = 'external_id',
  Description = 'description',
  ProductName = 'product_name',
  ProductExternalId = 'product_external_id',
  Size = 'size',
  Styles = 'styles',
  Colors = 'colors',
  Categories = 'categories',
  DepartmentName = 'department_name',
  DepartmentExternalId = 'department_external_id',
  Brands = 'brands',
  Seasons = 'seasons',
  Markets = 'markets',
  Cost = 'cost',
  Price = 'price',
  SiteQty = 'site_qty',
  TransitQty = 'transit_qty',
  WhQty = 'wh_qty',
  ReservedQty = 'reserved_qty',
  PackConstraint = 'pack_constraint',
  TotalStores = 'total_stores',
  SaleRate = 'sale_rate',
  Coverage = 'coverage',
  AvoidReplenishment = 'avoid_replenishment',
  CreatedAt = 'created_at',
}
