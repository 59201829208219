export enum LocationsColumns {
  Name = 'name',
  ExternalId = 'external_id',
  LocationType = 'location_type',
  Description = 'description',
  Region = 'region',
  City = 'city',
  Brands = 'brands',
  Classifications = 'classifications',
  AtSite = 'at_site',
  InTransit = 'in_transit',
  Reserved = 'reserved',
  NumOfProducts = 'num_of_products',
  NumOfSkus = 'num_of_skus',
  InventoryCost = 'inventory_cost',
  InventoryPrice = 'inventory_price',
  AvoidReplenishment = 'avoid_replenishment',
  CreatedAt = 'created_at',
}
