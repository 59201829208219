import { computed } from 'vue';
import pupa from 'pupa';
import { useAccountStore } from '../../../stores';
import { useCurrencySymbol } from '../../../services/i18n';
import { useSupersetKeyMetaStore } from '../stores';

export function useSupersetKeyMeta() {
  const accountStore = useAccountStore();
  const currencySymbol = useCurrencySymbol(accountStore.currency);
  const store = useSupersetKeyMetaStore();

  const context = computed(() => ({
    currency: currencySymbol.value,
  }));

  function trackReactivityValues() {
    return [store.keys];
  }

  function wrapWithDeps<R>(fn: () => unknown) {
    trackReactivityValues();
    return fn() as R;
  }

  function getTextForKey(key: string) {
    return wrapWithDeps<string>(() =>
      store.keys[key]?.text ? pupa(store.keys[key].text, context.value) : key,
    );
  }

  function getDescriptionForKey(key: string) {
    return wrapWithDeps<string>(() =>
      store.keys[key]?.description ? pupa(store.keys[key].description, context.value) : '',
    );
  }

  return {
    getTextForKey,
    getDescriptionForKey,
  };
}
