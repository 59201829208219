import { createApp as createVueApp } from 'vue';
import type { App as VueApp } from 'vue';
import * as Sentry from '@sentry/vue';
// TODO: fix this import, no idea for now why eslint doesn't like it
// eslint-disable-next-line import/no-unresolved
import { createHead } from '@unhead/vue/client';
import { createRouter } from '../router';
import { createStore } from '../stores';
import { createApi } from '../services/api';
import { createI18n } from '../services/i18n';
import { createHeap } from '../services/heap';
import { createUnleash } from '../services/unleash';
import { createToaster, createConfirmDialog } from '../ui-kit';
import { createRecentlyVisited } from '../modules/recently-visited';
import { config } from './config';
import { createEventBus } from './event-bus';
import App from './app.vue';

const router = createRouter({
  baseUrl: config.baseUrl,
});

export function createApp(): VueApp {
  const app = createVueApp(App)
    .use(createEventBus())
    .use(
      createApi({
        baseUrl: config.apiUrl,
        keycloak: {
          ...config.keycloak,
          logoutRedirectUri: `${window.location.origin}${config.baseUrl}`,
        },
        meilisearchHost: config.meilisearch.host,
        meilisearchTokenEndpoint: '/v1/meilisearch',
        metaMeilisearchHost: config.metaMeilisearch.host,
        metaMeilisearchTokenEndpoint: '/v1/meilisearch?type=meta',
      }),
    )
    .use(createStore())
    .use(router)
    .use(createI18n())
    .use(createHead())
    .use(
      createUnleash({
        url: config.unleash.url,
        clientKey: config.unleash.clientKey,
        appName: config.unleash.appName,
        environment: config.environment,
        refreshInterval: config.unleash.refreshInterval,
      }),
    )
    .use(createToaster())
    .use(createConfirmDialog())
    .use(createRecentlyVisited({ router }));

  if (config.heap.enabled) {
    app.use(createHeap(config.heap.appId));
  }

  if (config.sentry.enabled) {
    Sentry.init({
      app,
      environment: config.sentry.environment,
      dsn: config.sentry.dsn,
      integrations: [
        Sentry.browserTracingIntegration({
          router,
        }),
      ],
      tracePropagationTargets: ['localhost', /^\//],
      tracesSampleRate: 1,
      tracingOptions: {
        trackComponents: true,
      },
      attachProps: true,
      attachStacktrace: true,
      logErrors: config.sentry.environment !== 'production',
    });
  }

  app.config.idPrefix = 'onebeat';

  app.config.errorHandler = (error) => {
    if (config.sentry.enabled) {
      Sentry.captureException(error);
    }

    console.error(error);
  };

  return app;
}
